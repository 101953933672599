<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown"
    class="px-10"
  >
    <v-row>
      <v-col>
        <h1 class="my-5">オプションの設定</h1>
        <v-divider class="mb-3"></v-divider>
        <icon-info icon="lightbulb-on-outline" class="my-5" :square="true">
          オプションは金額の「低い→高い」の順にホームページで表示されます。<br />
        </icon-info>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12"
        v-if="options.length"
      >
        <v-card
          class="py-5 px-3"
          elevation="1"
        >
          <v-row no-gutters>
            <v-col cols="12" lg="6"
              class="mb-5 pt-3 px-5"
              v-for="(option, index) in options"
              :key="option.option_id"
            >
              <!-- 入力一列 -->
              <v-form :ref="'form-option-' + option.option_id">
                <v-row no-gutters>
                  <v-col cols="8"
                    class="px-1"
                  >
                    <v-text-field
                      v-model.trim="option.option_name"
                      required
                      label="オプション名"
                      :rules="[valiRules.required, valiRules.max20]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4"
                    class="px-1"
                  >
                    <v-text-field
                      v-model.trim="option.option_charge"
                      type="number"
                      required
                      label="料金（円）"
                      :rules="[valiRules.required, valiRules.price]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      class="pt-2"
                      counter="50"
                      v-model="option.description"
                      :rules="[valiRules.max50]"
                      label="補足説明（必要であれば）"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    class="d-flex justify-end align-center pt-0"
                  >
                    <v-btn
                      v-if="option.create"
                      depressed small
                      color="accent"
                      @click="createRow(option)"
                    >登録</v-btn>
                    <v-btn
                      v-else
                      depressed small
                      color="primary"
                      @click="updateRow(option)"
                    >更新</v-btn>
                    <v-btn
                      class="ml-2"
                      text
                      depressed small
                      color="primary"
                      @click="deleteRow(index)"
                    >削除</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <!-- 要素追加ボタン -->
      <v-col cols="12"
        class="mb-5"
      >
        <v-btn
        depressed
        color="primary"
        @click="addBlankRow()"
        >オプションを追加</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card v-if="!options.length" flat>
          <v-card-text>オプションが登録されていません。</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import $literals from '@/literals.js'
import { SITE_API_ENDPOINT } from '@/literals.js'
import { ApiTool, CheckTokenError, ValidationRules } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import IconInfo from "@/components/_IconInfo.vue";

export default {
  components: {
    'loader': Loader,
    'icon-info': IconInfo,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      maxId: 0,
      options: [],
      loading: false,
      loadingMessage: '',
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
      publicApi: new ApiTool(SITE_API_ENDPOINT + '/', this.shopData),
    };
  },

  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
  },

  created() {
    this.adminApi.setToken(this.serverToken)

    this.getData()
    .catch(error => {
      if (CheckTokenError(error)) this.$emit('reset')
      else {
        alert($literals.MESSAGE.failedApiGet + error.response.data + error.response.status)
      }
    })
  },

  methods: {
    async getData() {
      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderLoading

      await Promise.all([
        this.getOptions(),
      ])

      this.loading = false
    },

    //入力列追加
    addBlankRow() {
      if (this.options.length >= 30) {
        this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoMaxedupRows, open: true}}
        return
      }

      this.options.push({
        option_id: ++this.maxId,
        option_name: '',
        option_charge: null,
        description: '',
        create: true
      })
    },

    //**************************************************
    //**************************************************
    //                    APIコール
    //**************************************************
    //**************************************************
    // API req: GET
    //**************************************************
    getOptions() {
      return this.publicApi.getReqSitePublic('option/').then( results => {
        if (!results || !results.length) return

        //id最大値の取得
        this.maxId = Math.max(...results.map( row => parseInt(row.option_id) ))

        this.options = results
      })
    },

    //**************************************************
    //新規登録
    //**************************************************
    createRow(option) {
      if (!this.$refs['form-option-' + option.option_id][0].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderInTarnsaction

      const formData = new FormData();
      formData.append("option_name", option.option_name);
      formData.append("option_charge", option.option_charge);
      formData.append("description", option.description);

      this.adminApi.apiReqWithData('POST', 'create/option/', formData).then( response => {
        option.option_id = response.option_id
        option.create = false

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successCreateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //更新
    //**************************************************
    updateRow(option) {
      if (!this.$refs['form-option-' + option.option_id][0].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      const apiPath = 'update/option/' + option.option_id
      const formData = new FormData();
      formData.append("option_name", option.option_name);
      formData.append("option_charge", option.option_charge);
      formData.append("description", option.description);

      this.adminApi.apiReqWithData('PUT', apiPath, formData).then( response => {
        if (response.NoRowsAffected) {
          this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoNoRowsAffected, open: true}}
        } else {
          this.snackbar = {...{color:'success', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
        }
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },

    //**************************************************
    //削除
    //**************************************************
    deleteRow(index) {
      if (this.options[index].create) {
        this.options.splice(index, 1)
        return
      }

      const apiPath = 'delete/option/' + this.options[index].option_id

      this.adminApi.apiReqWithData('DELETE', apiPath).then(() => {
        this.options.splice(index, 1)

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successDeleteSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },
  }
}
</script>

<style scoped>
>>> .v-input__slot {
  margin-bottom: 4px;
}
</style>
